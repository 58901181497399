.main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100vh; 
    border-radius: 26.33px;
    border: 11px solid #DFD5EC;
    background: #131313;
}

.side {
    background: rgba(128, 128, 128, 0.5); 
    display: flex;
    width: 79.869px;
    min-width: 79.869px;
    padding: 8.777px;
    flex-direction: column;
    align-items: center;
    gap: 8.777px;
    height: 100%;
}

.hemburgermenu {
    display: flex;
    width: 70.215px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
}

.add-icon {
    width: 25.064px;
    height: 25.064px;
}

.add_icon_div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6.043px;
    background: var(--Brand-Secondary, #FFC47F);
    box-shadow: 0px 3.511px 7.021px 2.633px rgba(0, 0, 0, 0.15), 0px 0.878px 2.633px 0px rgba(0, 0, 0, 0.30);
}

.userimage {
    width: 21.064px;
    height: 21.064px;
}

.below-icons {
    margin-top: 600px;
}

.setting_image {
    margin-top: 15px;
}

.inbuilt-prompt {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
}

.inbuilt-prompt-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 200px;
    max-width: 222.931px;
    min-height: 198.356px;
    border: 1px solid #333;
    margin-top: 30%;
    border-radius: 10.532px;
    position: relative;
    padding: 10px;
}

.book-icon-div .openbook-icn {
    width: 24px;
    height: 24px;
    background: #E9CA91;
    border-radius: 8px;
}

.book-story {
    font-size: 20px;
    color: #B5B5B5;
    font-family: 'Outfit', sans-serif;
}

.book-story-para {
    color: #B5B5B5;
    font-size: 15px;
    line-height: 18px;
    position: static;
    margin-top: 10px;
}

.arrowcirclebrokenright {
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.input-wrapper {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 60%;
    padding: 10px;
    box-sizing: border-box;
    right: 20%;
    bottom: 30px;
}

.input-box {
    flex-grow: 1;
    border-radius: 28px;
    padding: 10px 40px 10px 10px;
    background: #242424;
    color: #fff;
    border: none;
}

.icons-container {
    display: flex;
    gap: 10px;
    position: absolute; 
    right: 40px; 
    bottom: 24px;
}

.input-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.send-icon {
    width: 45px;
    position: absolute;
    left: 100%; 
    bottom: 24px;
    cursor: pointer;
    top: 19px;
}

.chat-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
    width: 55%;
    height: 80vh; 
    background: rgba(19, 19, 19, 0.8); 
    /* border: 2px solid red; */
    
}


.chat-message {
    text-align: right;
    margin-right: 20px;
}

.chat-response,
.input-query {
    width: 80%;
    color: grey;
    background: transparent;
    border-radius: 8px;
    padding: 10px 20px;
    display: inline-block;
    margin-bottom: 10px;
}

.input-query {
    text-align: left;
    margin-left: auto;
    margin-right: 20px;
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown {
    position: absolute;
    bottom: 35px; 
    left: -60px;
    background-color: #242424;
    border: 1px solid #ccc;
    border-radius: 8px;
    z-index: 1;
    width: 150px;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.dropdown select {
    width: 100%;
    background-color: #333;
    color: #fff;
    border: none;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
}

.dropdown select option {
    background-color: #242424;
    /* border: 2px solid red; */
}

.listening-animation div,
.speaking-animation div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
}

.listening-animation div div,
.speaking-animation div div {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    animation: listening 1s infinite ease-in-out;
}

@keyframes listening {
    0%, 40%, 100% { transform: scale(0.6); }
    20% { transform: scale(1); }
}

/* Custom scrollbar styles */
.chat-container::-webkit-scrollbar {
    width: 8px;
}

.chat-container::-webkit-scrollbar-track {
    background: transparent;
}

.chat-container::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 10px;
    border: 3px solid transparent;
}


/* Add this CSS to your Components.css file */


/* Listening Animation */
.listening-animation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .listening-animation div {
    width: 15px;
    height: 15px;
    background-color: #00bcd4;
    border-radius: 50%;
    margin: 0 5px;
    animation: pulsing 1s infinite;
  }
  
  .listening-animation div:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .listening-animation div:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  .listening-animation div:nth-child(4) {
    animation-delay: 0.6s;
  }
  
  .listening-animation div:nth-child(5) {
    animation-delay: 0.8s;
  }
  
  @keyframes pulsing {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

/* Speaking Animation */
.speaking-animation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .speaking-animation div {
    position: absolute;
    width: 80px;
    height: 80px;
    border: 4px solid rgba(76, 175, 80, 0.7);
    border-radius: 50%;
    animation: concentric 1.2s infinite;
  }
  
  .speaking-animation div:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .speaking-animation div:nth-child(3) {
    animation-delay: 0.8s;
  }
  
  @keyframes concentric {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
.user-name {
    position: absolute;
    right: 0;
    padding: 10px;
    margin-right: 20px;
    margin-top: 0px;
    color: transparent; /* This makes the text color transparent so the background gradient is visible */
    background: -webkit-linear-gradient(16deg, #FF6B6B 0%, #FFD93D 9%, #6BFFB2 20%, #6BFFB2 24%, #FFD93D 35%, #FF6B6B 44%, #FFD93D 50%, #6BFFB2 56%, #1ec06c 75%, #FFD93D 100%);
    background-clip: text;
    -webkit-background-clip: text;
}


  


/* Mobile view styles */
/* Existing CSS code for desktop and other components remains unchanged */

/* Mobile view styles */
@media (max-width: 768px) {
  
    .main-container {
      flex-direction: column;
      width: 100%;
      height: 100vh;
      border-radius: 10px;
      border: 5px solid #DFD5EC;
      background: #131313;
      padding: 10px;
    }
    
    .inbuilt-prompt {
        /* border: 2px solid green; */
      gap: 5px;
      position: relative;
      top: 30%;
      width: 75%;
    }
  
    .inbuilt-prompt-div {
      display: none; /* Hide all prompts by default */
    }
  
    .inbuilt-prompt-div:first-child {
      display: flex; /* Show only the first prompt */
      flex: 1 1 100%;
      max-width: 100%;
      min-height: 150px;
      margin-top: 10%;
      padding: 5px;
    }
  
    .input-wrapper {
      display: flex;
      flex-direction: row; /* Changed from column to row */
      align-items: center; /* Center align items vertically */
      justify-content: space-between; /* Space out the items */
      width: 100%;
      padding: 5px;
      position: relative;
      bottom: 0; /* Corrected from 'bottom: 300' to 'bottom: 0' */
      left: 0; /* Corrected from 'left: 40px' to 'left: 0' */
      box-sizing: border-box; /* Ensure padding and border are included in the width */
    }
  
    .input-box {
      flex-grow: 1;
      margin-bottom: 5px;
      border-radius: 14px;
      padding: 10px;
      background: #242424;
      color: #fff;
      border: none;
      box-sizing: border-box; /* Ensure padding and border are included in the width */
      
    }
  
    .icons-container {
      display: flex;
      gap: 10px;
      justify-content: center; 
      position: relative;
      right: 0;
      bottom: 0;
      margin-left: 10px;
      }
      
      /* .send-icon {
          width: 35px;
          position: relative;
          left: auto;
          top: 0;
          cursor: pointer;
          margin-left: 20px;
          } */
  
    .chat-container {
      width: 95%;
      height: 86vh;
      /* border: 2px solid red; */
      padding: 5px;
      }
      
      .input-query,
      .chat-response {
          width: 90%;
          margin-left: 5%;
    
          }
          
    .dropdown select option {
        background-color: #242424;
        /* border: 2px solid red; */
        }
        }




